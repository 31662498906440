<template>
  <div class="inner-section">
    <card>
        <!-- search section start -->
        <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('lrcpnServicePanel.formalin_license_application') }} {{$t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
        <b-row>
          <b-col xs="12" sm="12" md="4">
            <ValidationProvider name="Applicant Telephone" vid="telephone_number" rules="" v-slot="{ errors }">
              <b-form-group
                label-for="telephone_number">
                <template v-slot:label>
                  {{ $t('externalUser.telephone_no') }}
                </template>
                <b-form-input
                  id="telephone_number"
                  v-model="search.telephone_number"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="3" style="text-align: right;">
            <b-button size="sm" type="submit" variant="primary" class="mt-20">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
        </b-form>
      </ValidationObserver>
      </template>
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('lrcpnServicePanel.formalin_license_application') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <router-link :to="{ name: 'lrcpn_service.formalin_import_management.formalin_license_from'}" class="btn-add"><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</router-link>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(applicant_name)="data">
                    {{ currentLocale == 'bn' ? data.item.applicant_name_bn : data.item.applicant_name_en }}
                  </template>
                  <template v-slot:cell(company_name)="data">
                    {{ currentLocale == 'en' ? data.item.business_info?.company_name : data.item.business_info?.company_name_bn }}
                  </template>
                  <template v-slot:cell(father_name)="data">
                    {{ currentLocale == 'bn' ? data.item.father_spouse_name_bn : data.item.father_spouse_name_en }}
                  </template>
                  <template v-slot:cell(telephone)="data">
                    {{ data.item.telephone_number | mobileNumber }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge badge-warning" v-if="data.item.status === 1 ">{{$t('globalTrans.pending')}}</span>
                    <span class="badge badge-success" v-if="data.item.status === 2">{{$t('globalTrans.processing')}}</span>
                    <span class="badge badge-danger" v-if="data.item.status === 3">{{$t('globalTrans.rejected')}}</span>
                    <span class="badge badge-success" v-if="data.item.status === 4">{{$t('globalTrans.approved')}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <router-link v-if="data.item.status === 1" class="action-btn active view" variant=" iq-bg-warning mr-1" :title="$t('globalTrans.edit')" :to="`/lrcpn-service/formalin-import-management/license-form-edit/${data.item.id}`"><i class="ri-pencil-fill"></i></router-link>
                    <b-button v-b-modal.modal-details variant=" iq-bg-success mr-1" size="sm" @click="details(data.item)" class="action-btn edit" :title="$t('globalTrans.details')"><i class="ri-eye-fill"></i></b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-details" size="lg" :title="viewTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <Details :items="items" :key="viewitemId"/>
    </b-modal>
  </div>
</template>
<script>
import Details from './Details.vue'
import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
import { applicationList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
     Details
  },
  data () {
    return {
      lrcpnServiceBaseUrl: lrcpnServiceBaseUrl,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      search: {
        telephone_number: ''
      },
      items: [],
      viewitemId: 0,
      labelData: [
        { labels: 'globalTrans.applicant_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'globalTrans.company_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'globalTrans.application_id', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'lrcpnServicePanel.father_spouse_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
        { labels: 'externalUser.telephone_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 6, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 7, thStyle: { width: '5%' } }
      ],
      actions: {
        edit: false,
        details: true,
        toogle: false,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    typeOfBusinessList () {
       return this.$store.state.ExternalUserService.eBizPanel.commonObj.typeOfBusinessList.filter(item => item.status === 1)
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'applicant_name' },
          { key: 'company_name' },
          { key: 'application_id' },
          { key: 'father_name' },
          { key: 'telephone' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'applicant_name' },
          { key: 'company_name' },
          { key: 'application_id' },
          { key: 'father_name' },
          { key: 'telephone' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    paymentTitle () {
      return this.$t('lrcpnServicePanel.application_payment')
    },
    viewTitle () {
      return this.$t('InformationTraining.business_directory') + ' ' + this.$t('globalTrans.details')
    }
  },
  methods: {
    edit (item) {
        this.editItemId = item.id
    },
    details (item) {
      this.items = item
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(lrcpnServiceBaseUrl, applicationList, params)
      if (result.success) {
        const listData = result.data.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
      } else {
        this.$store.dispatch('setList', [])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
